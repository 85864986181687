import React, { useContext, useEffect, useState } from "react";
import UserContext from "../context/UserContext";
import { fetchUserData, updatePassword } from "../api/auth";

export default function MyProfile() {
  const [user, setUser] = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // Fetch user information
  useEffect(() => {
    if (user) {
      const fetchUser = async () => {
        try {
          const userData = await fetchUserData();
          setUserInfo(userData);
        } catch (error) {
          console.error("Failed to fetch user data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchUser();
    }
  }, [user]);

  // Handle password change
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle password update
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (passwords.newPassword !== passwords.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      await updatePassword({ newPassword: passwords.newPassword });
      setSuccessMessage("Password updated successfully!");
      setPasswords({ newPassword: "", confirmPassword: "" }); // Clear password fields
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Failed to update password."
      );
    }
  };

  if (loading) return <p>Loading profile...</p>;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">My Profile</h2>
      <form>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Full Name
            </label>
            <input
              type="text"
              id="name"
              value={userInfo.fullName || ""}
              readOnly
              className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={userInfo.email || ""}
              readOnly
              className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="civilID"
              className="block text-sm font-medium text-gray-700"
            >
              Civil ID
            </label>
            <input
              type="text"
              id="civilID"
              value={userInfo.civilID || ""}
              readOnly
              className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="phone"
              value={userInfo.phoneNumber || ""}
              readOnly
              className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-lg font-medium text-gray-800 mb-4">
            Update Password
          </h3>
          <div className="space-y-6">
            <div>
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handlePasswordChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handlePasswordChange}
                className="block w-full mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              onClick={handleUpdatePassword}
              className="rounded-md bg-sky-600 px-4 py-2 text-white font-semibold shadow-sm hover:bg-sky-500"
            >
              Update Password
            </button>
          </div>

          {/* Success/Error Messages */}
          {errorMessage && (
            <p className="mt-4 text-sm font-semibold text-red-600">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className="mt-4 text-sm font-semibold text-green-600">
              {successMessage}
            </p>
          )}
        </div>
      </form>
    </div>
  );
}
