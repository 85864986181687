import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { register } from "../api/auth";
import UserContext from "../context/UserContext";

const Register = () => {
  const [, setUser] = useContext(UserContext); // Use only setUser

  const [userInfo, setUserInfo] = useState({
    civilID: "",
    fullName: "",
    phoneNumber: "",
    fileNumber: "",
    email: "",
    password: "",
    confirmPassword: "", // Add confirmPassword to state
  });

  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const navigate = useNavigate();

  // Handle input change
  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  // Mutation function to register user
  const { mutate, isLoading } = useMutation({
    mutationFn: () => register(userInfo),
    onSuccess: () => {
      setUser(true); // set user fols or remove it
      navigate("/login"); // nav logein
    },
    onError: (error) => {
      setErrorMessage(error.message); // Handle error from API
    },
  });

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (userInfo.password !== userInfo.confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    mutate(); // Call the mutation if passwords match
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-sky-700 to-sky-500">
      <div className="w-full max-w-md px-8 py-6 bg-gray-900 rounded-lg shadow-md">
        <h2 className="text-3xl text-white font-bold mb-8 text-center">
          Register
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label
              htmlFor="civilID"
              className="block text-white text-sm font-medium mb-2"
            >
              Civil ID
            </label>
            <input
              type="text"
              name="civilID"
              id="civilID"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="fullName"
              className="block text-white text-sm font-medium mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phoneNumber"
              className="block text-white text-sm font-medium mb-2"
            >
              Phone Number
            </label>
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="fileNumber"
              className="block text-white text-sm font-medium mb-2"
            >
              File Number
            </label>
            <input
              type="text"
              name="fileNumber"
              id="fileNumber"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-white text-sm font-medium mb-2"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-white text-sm font-medium mb-2"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          <div className="mb-6">
            <label
              htmlFor="confirmPassword"
              className="block text-white text-sm font-medium mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          {errorMessage && (
            <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
          )}

          <div className="flex justify-center">
            <button
              type="submit"
              className={`w-full px-4 py-2 text-white bg-sky-500 rounded-md hover:bg-sky-700 transition duration-200 ease-in-out ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Registering..." : "Register"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
