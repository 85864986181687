import React, { useEffect, useState } from "react";
import { myUser } from "../api/auth";
import UserRequestCard from "../components/UserRequestCard";

const UserRequestStatuses = () => {
  //   const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const data = await myUser();
        setUser(data);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch statuses");
      } finally {
        setLoading(false);
      }
    };
    fetchStatuses();
  }, []);

  if (loading) return <p>Loading statuses...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">My Request Statuses</h2>
      <p>Total Requests: {user.requests?.length || 0}</p>
      {user.requests?.length > 0 ? (
        user.requests.map((request) => (
          <UserRequestCard key={request._id} request={request} />
        ))
      ) : (
        <p className="text-gray-500">No requests found.</p>
      )}
    </div>
  );
};

export default UserRequestStatuses;
