import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Request from "./pages/Request";
import FileUpload from "./pages/FileUpload";
import TimeTable from "./pages/TimeTable";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UserRequests from "./pages/UserRequests";
import MyProfile from "./pages/MyProfile";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/request",
        element: <Request />,
      },
      {
        path: "/fileUpload",
        element: <FileUpload />,
      },
      {
        path: "/timeTable",
        element: <TimeTable />,
      },
      {
        path: "/userRequests",
        element: <UserRequests />,
      },
      {
        path: "/profile",
        element: <MyProfile />,
      },
      {
        path: "/forgotPassword",
        element: <ForgotPassword />,
      },
      {
        path: "/resetPassword/:token",
        element: <ResetPassword />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
