import instance from ".";
import { saveToken } from "./storage";

// Login API call
const login = async (userInfo) => {
  try {
    console.log(userInfo); // Debugging log
    const { data } = await instance.post("/api/auth/login", userInfo);

    if (data.token) {
      saveToken(data.token); // Save the token to storage
    }

    return data;
  } catch (error) {
    console.error(
      "Login error:",
      error.response ? error.response.data : error.message
    );
    throw new Error(error.response?.data?.message || "Login failed");
  }
};

// Register API call
const register = async (userInfo) => {
  const formData = new FormData();
  for (const key in userInfo) {
    formData.append(key, userInfo[key]);
  }

  const { data } = await instance.post("/api/auth/submit", userInfo);
  if (data.token) {
    saveToken(data.token);
  }

  return data;
};

// Get current user data
const myUser = async () => {
  const { data } = await instance.get("/api/auth/me");
  return data;
};

// Optional: Update user profile
const updateMyProfile = async (updatedInfo) => {
  const formData = new FormData();
  for (const key in updatedInfo) {
    formData.append(key, updatedInfo[key]);
  }

  const { data } = await instance.put("/api/auth/me", formData); // Adjusted route
  return data;
};
// fetch
const fetchUserData = async () => {
  const token = localStorage.getItem("token");
  const { data } = await instance.get("/api/auth/me", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Cache-Control": "no-cache", // This ensures the request fetches fresh data
    },
  });
  return data;
};
//Create a Request
const createRequest = async (request) => {
  // const formData = new FormData();
  // formData.append("requestType", request.requestType);
  // formData.append("additionalInfo", request.additionalInfo);
  // formData.append("leaveStart", request.leaveStart);
  // formData.append("leaveEnd", request.leaveEnd);
  // console.log(formData);
  const { data } = await instance.post("/api/requests/makerequest", request);
  return data;
};

// creatUpload lets test it
// const [file, setFile] = useState(null);
// const creatUpload = async (e) => {
//   e.preventDefault();
//   const formData = new FormData();
//   formData.append("file", file);
//   formData.append("name", userInfo.fullName);
//   formData.append("center", userInfo.center);

//   try {
//     const response = await instance.post("/api/upload", formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     console.log(response.data);
//   } catch (error) {
//     console.error("Error uploading file:", error.message);
//   }
// };

const uploadFile = async (file, userInfo) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", userInfo.fullName);
  formData.append("center", userInfo.center);

  try {
    const response = await instance.post("/api/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error.message);
    throw error;
  }
};
// test for notification get request by id
const getRequestById = async (id) => {
  const { data } = await instance.get(`/api/requests/getrequest/${id}`);
  return data;
};
const updateRequest = async (request) => {
  const { data } = await instance.put("/api/requests/updateRequest", request);
  return data;
};
const getRequests = async () => {
  const { data } = await instance.get("/api/requests/getrequests");
  return data;
};
//last test

// const getUserRequests = async () => {
//   const { data } = await instance.get("/api/requests/getUserRequests");
//   return data;
// };

// const getUserRequests = async () => {
//   const { data } = await instance.get("/api/requests/getUserRequests", {
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   });
//   return data;
// };

// const getUserRequests = async () => {
//   try {
//     const { data } = await instance.get("/api/requests/getUserRequests", {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`, // Use backticks for string interpolation
//       },
//     });
//     return data;
//   } catch (error) {
//     console.error("Error fetching user requests:", error);
//     throw error; // Re-throw error to handle it in the calling function
//   }
// };

// const getUserRequests = async () => {
//   const { data } = await instance.get("/api/requests/userRequests");
//   return data;
// };

// const getUserRequests = async () => {
//   const { data } = await axios.get("/api/requests/userRequests/status", {
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   });
//   return data;
// };
const getTable = async () => {
  const token = localStorage.getItem("token");

  const { data } = await instance.get("/api/files/latest/table", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

const posttable = async (file) => {
  const formData = new FormData();
  formData.append("folder", "doc");
  formData.append("file", file);

  const { data } = await instance.post("/api/files/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

const updatePassword = async (passwordData) => {
  const { data } = await instance.put(
    "/api/auth/update-password",
    passwordData
  );
  return data;
};

export {
  login,
  register,
  myUser,
  updateMyProfile,
  fetchUserData,
  createRequest,
  uploadFile,
  getRequestById,
  updateRequest,
  getRequests,
  // getUserRequests,
  getTable,
  posttable,
  updatePassword,
};
