// // src/pages/Login.js   frontend
// import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { login } from "../api/auth";
// import UserContext from "../context/UserContext";
// import { useMutation } from "@tanstack/react-query";

// const Login = () => {
//   const [userInfo, setUserInfo] = useState({
//     civilID: "",
//     password: "",
//   }); // Initialize state with expected fields

//   const [errorMessage, setErrorMessage] = useState(""); // Track login errors
//   const [, setUser] = useContext(UserContext); // Use only setUser
//   const navigate = useNavigate();

//   // Mutation for login
//   const { mutate, isLoading } = useMutation({
//     mutationFn: () => login(userInfo),
//     mutationKey: ["login"],
//     onSuccess: () => {
//       setUser(true);
//       navigate("/"); // Redirect to home on success
//     },
//     onError: (error) => {
//       setErrorMessage(error.response?.data?.message || "Login failed");
//     },
//   });

//   // Handle input changes
//   const handleChange = (e) => {
//     setUserInfo((prev) => ({
//       ...prev,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   // Handle form submission
//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (!userInfo.civilID || !userInfo.password) {
//       setErrorMessage("Please fill in all fields");
//       return;
//     }
//     mutate();
//   };

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-sky-500 to-sky-700">
//       <div className="w-full max-w-md px-8 py-6 bg-gray-900 rounded-lg shadow-md">
//         <h2 className="text-3xl text-white font-bold mb-8 text-center">
//           Login
//         </h2>
//         <form onSubmit={handleFormSubmit}>
//           <div className="mb-4">
//             <label
//               htmlFor="civilID"
//               className="block text-white text-sm font-medium mb-2"
//             >
//               Civil ID
//             </label>
//             <input
//               type="text"
//               name="civilID" // Changed from 'username' to 'civilID'
//               id="civilID"
//               onChange={handleChange}
//               className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
//               required
//             />
//           </div>
//           <div className="mb-6">
//             <label
//               htmlFor="password"
//               className="block text-white text-sm font-medium mb-2"
//             >
//               Password
//             </label>
//             <input
//               name="password"
//               type="password"
//               id="password"
//               onChange={handleChange}
//               className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
//               required
//             />
//           </div>

//           {errorMessage && (
//             <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
//           )}

//           <div className="flex justify-center">
//             <button
//               type="submit"
//               className={`w-full px-4 py-2 text-white bg-sky-500 rounded-md hover:bg-sky-700 transition duration-200 ease-in-out ${
//                 isLoading ? "opacity-50 cursor-not-allowed" : ""
//               }`}
//               disabled={isLoading}
//             >
//               {isLoading ? "Logging in..." : "Login"}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Login;

import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../api/auth";
import UserContext from "../context/UserContext";
import { useMutation } from "@tanstack/react-query";

const Login = () => {
  const [userInfo, setUserInfo] = useState({
    civilID: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => login(userInfo),
    mutationKey: ["login"],
    onSuccess: () => {
      setUser(true);
      navigate("/"); // Redirect to home on success
    },
    onError: (error) => {
      setErrorMessage(error.response?.data?.message || "Login failed");
    },
  });

  const handleChange = (e) => {
    setUserInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!userInfo.civilID || !userInfo.password) {
      setErrorMessage("Please fill in all fields");
      return;
    }
    mutate();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-sky-500 to-sky-700">
      <div className="w-full max-w-md px-8 py-6 bg-gray-900 rounded-lg shadow-md">
        <h2 className="text-3xl text-white font-bold mb-8 text-center">
          Login
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label
              htmlFor="civilID"
              className="block text-white text-sm font-medium mb-2"
            >
              Civil ID
            </label>
            <input
              type="text"
              name="civilID"
              id="civilID"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-white text-sm font-medium mb-2"
            >
              Password
            </label>
            <input
              name="password"
              type="password"
              id="password"
              onChange={handleChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
              required
            />
          </div>

          {errorMessage && (
            <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
          )}

          <div className="flex justify-center">
            <button
              type="submit"
              className={`w-full px-4 py-2 text-white bg-sky-500 rounded-md hover:bg-sky-700 transition duration-200 ease-in-out ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </div>
        </form>

        <div className="mt-4 text-center">
          <button
            onClick={() => navigate("/forgotPassword")}
            className="text-sky-300 hover:underline hover:text-sky-500 text-sm"
          >
            Forgot Password?
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
