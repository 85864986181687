import { useContext, useState, useEffect } from "react";
import UserContext from "../context/UserContext";
import { fetchUserData } from "../api/auth";
import { useMutation } from "@tanstack/react-query";
import { createRequest } from "../api/auth";
import { useNavigate } from "react-router-dom";

export default function Requestform() {
  const [user, setUser] = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({});
  const [formData, setFormData] = useState({
    requestType: "",
    additionalInfo: "",
    startDate: "",
    endDate: "",
  });
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [leaveDates, setLeaveDates] = useState({
    start: "",
    end: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      const fetchUser = async () => {
        try {
          const userData = await fetchUserData();
          setUserInfo(userData);
        } catch (error) {
          console.log(error);
        }
      };
      fetchUser();
    }
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Trigger modal for specific leave types
    if (
      [
        "Sick Leave",
        "Periodic Leave",
        "Emergency Leave",
        "Permission Beginning",
        "Permission Ending",
        "Return From Leave",
      ].includes(value)
    ) {
      setShowLeaveModal(true);
    }
  };

  // const { mutate } = useMutation({
  //   mutationFn: createRequest,
  //   mutationKey: ["createRequest"],
  //   onSuccess: (data) => {
  //     console.log("Request sent successfully:", data);
  //     setSuccessMessage("Your request has been sent successfully!");
  //   },
  //   onError: (error) => {
  //     console.log("Error creating request:", error);
  //     setSuccessMessage(
  //       "There was an error sending your request. Please try again."
  //     );
  //   },
  // });
  const { mutate } = useMutation({
    mutationFn: createRequest,
    mutationKey: ["createRequest"],
    onSuccess: (data) => {
      console.log("Request sent successfully:", data);
      setSuccessMessage("Your request has been sent successfully!");

      // Clear form data
      setFormData({
        requestType: "",
        additionalInfo: "",
        startDate: "",
        endDate: "",
      });

      // Reset leave dates and close modal if open
      setLeaveDates({ start: "", end: "" });
      setShowLeaveModal(false);
    },
    onError: (error) => {
      console.log("Error creating request:", error);
      setSuccessMessage(
        "There was an error sending your request. Please try again."
      );
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.requestType && (!leaveDates.start || !leaveDates.end)) {
      alert("Please select the start and end dates for the leave.");
      return;
    }
    mutate({
      ...formData,
      startDate: leaveDates.start,
      endDate: leaveDates.end,
      centerName: userInfo.center, // Include centerName
      supervisorName: userInfo.supervisor, // Include supervisorName
    });
  };

  const handleModalSave = () => {
    setFormData((prevData) => ({
      ...prevData,
      startDate: leaveDates.start,
      endDate: leaveDates.end,
    }));
    setShowLeaveModal(false);
  };

  return (
    user && (
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base text-left font-semibold leading-7 text-gray-900">
                Request Form
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This information will be displayed publicly, so be careful what
                you share.
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label
                  htmlFor="civil_id"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Civil ID
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="civil_id"
                      name="civilID"
                      type="text"
                      value={userInfo.civilID || ""}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={userInfo.fullName || ""}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  phone number
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      value={userInfo.phoneNumber || ""}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="fileNumber"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  File Number
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600 sm:max-w-md">
                    <input
                      id="fileNumber"
                      name="fileNumber"
                      type="text"
                      value={userInfo.fileNumber || ""}
                      readOnly
                      className="block flex-1 border-0 bg-gray-100 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="center"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Center Name
                </label>
                <div className="mt-2">
                  <input
                    id="center"
                    name="center"
                    type="text"
                    value={userInfo.center || ""}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, center: e.target.value })
                    }
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="supervisor"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Supervisor Name
                </label>
                <div className="mt-2">
                  <input
                    id="supervisor"
                    name="supervisor"
                    type="text"
                    value={userInfo.supervisor || ""}
                    onChange={(e) =>
                      setUserInfo({ ...userInfo, supervisor: e.target.value })
                    }
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="requestType"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Request
                </label>
                <div className="mt-2">
                  <select
                    id="requestType"
                    name="requestType"
                    value={formData.requestType}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="" disabled>
                      Please choose your request
                    </option>
                    <option>Permission Beginning</option>
                    <option>Permission Ending</option>
                    <option>Periodic Leave</option>
                    <option>Sick Leave</option>
                    <option>Emergency Leave</option>
                    <option>Return From Leave</option>
                    <option>Cancel Permission</option>
                  </select>
                </div>
              </div>

              {[
                "Sick Leave",
                "Periodic Leave",
                "Emergency Leave",
                "Permission Ending",
                "Permission Beginning",
                "Return From Leave",
                "Cancel Permission",
              ].includes(formData.requestType) && (
                <div className="col-span-full">
                  <label className="block text-left text-sm font-medium leading-6 text-gray-900">
                    Leave Dates
                  </label>
                  <div className="mt-2 text-gray-700">
                    From: {leaveDates.start || "Not selected"} <br />
                    To: {leaveDates.end || "Not selected"}
                  </div>
                </div>
              )}

              <div className="col-span-full">
                <label
                  htmlFor="additionalInfo"
                  className="block text-left text-sm font-medium leading-6 text-gray-900"
                >
                  Additional Information
                </label>
                <div className="mt-2">
                  <textarea
                    id="additionalInfo"
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleChange}
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Write a few sentences about your request.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Send
          </button>
        </div>

        {/* Display success message if present */}
        {successMessage && (
          <div className="mt-4 text-center text-green-600 font-semibold">
            {successMessage}
          </div>
        )}

        {showLeaveModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
              <h3 className="text-xl font-semibold text-gray-800 mb-6 text-center">
                Select Leave Date
              </h3>
              <div className="mb-4">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={leaveDates.start}
                  onChange={(e) =>
                    setLeaveDates({ ...leaveDates, start: e.target.value })
                  }
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-transparent"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={leaveDates.end}
                  onChange={(e) =>
                    setLeaveDates({ ...leaveDates, end: e.target.value })
                  }
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-transparent"
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition"
                  onClick={() => setShowLeaveModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-white bg-sky-600 rounded-md shadow hover:bg-sky-500 transition"
                  onClick={handleModalSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    )
  );
}
