// import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { getToken } from "./api/storage";
// import UserContext from "./context/UserContext";
// import Navbar from "./components/Navbar";

// function App() {
//   const [user, setUser] = useState(false);

//   useEffect(() => {
//     const token = getToken();
//     if (token) {
//       setUser(true);
//     }
//   }, []);

//   return (
//     <UserContext.Provider value={[user, setUser]}>
//       <div className="App">
//         <Navbar />
//         <Outlet />
//       </div>
//     </UserContext.Provider>
//   );
// }

// export default App;

import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { getToken } from "./api/storage";
import UserContext from "./context/UserContext";
import Navbar from "./components/Navbar";
import Navbar2 from "./components/Navbar2";

function App() {
  const [user, setUser] = useState(false);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const token = getToken();
  //   if (token) {
  //     setUser(true);
  //   } else {
  //     navigate("/register");
  //   }
  // }, [navigate]);

  const checkToken = async () => {
    const token = await getToken();
    if (token) {
      setUser(true);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  // useEffect(() => {
  //   const token = getToken();
  //   if (token) {
  //     setUser(true);
  //   }
  // }, []); old one

  // const navigate = useNavigate();

  // const isAuthenticated = () => {
  //   return !!localStorage.getItem("token");
  // };

  // useEffect(() => {
  //   if (!isAuthenticated()) {
  //     navigate("/register");
  //   }
  // }, [navigate]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      <div className="App">
        <Navbar />
        <Outlet />
      </div>
    </UserContext.Provider>
  );
}

export default App;
