import React from "react";
import Requestform from "../components/Requestform";

const Request = () => {
  return (
    <div className="flex min-h-full py-12 px-4 sm:px-6 lg:px-8">
      <Requestform />
    </div>
  );
};

export default Request;
